import { Outlet, useLoaderData } from '@remix-run/react';

import { LuckyOrange, useLuckyOrange } from 'analytics';
import { checkUnAuthenticated } from 'leap-auth';

import { dashboardUrl } from '~/helpers/routes';
import { i18n } from '~/i18n.server';

import type { LoaderFunctionArgs, MetaFunction } from '@remix-run/cloudflare';

export async function loader(args: LoaderFunctionArgs) {
  return await checkUnAuthenticated(args, dashboardUrl, async () => {
    const t = await i18n.getFixedT(args.request);

    return {
      appConfig: {
        luckyOrangeId: args.context.env.LUCKY_ORANGE_ID,
      },
      meta: [
        { title: t('onboarding.meta.title') },
        {
          name: 'description',
          content: t('onboarding.meta.description'),
        },
      ],
    };
  });
}
export const meta: MetaFunction<typeof loader> = ({ data }) => {
  if (!data) return [];
  return data.meta;
};

export default function Onboarding() {
  let { appConfig } = useLoaderData<typeof loader>();
  useLuckyOrange({}); // TODO: figure out anonymous tracking

  return (
    <>
      <Outlet />
      <LuckyOrange luckyOrangeId={appConfig.luckyOrangeId} />
    </>
  );
}
